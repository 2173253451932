import { useTranslate } from 'react-polyglot';

const LoadingData = () => {
    const t = useTranslate();

    return (
        <div>
            <img className="loading-homepage" src={"./btn_loading.gif"} alt={`${t('common_loading')}...`} />
            {t('common_loading_data')}
        </div >
    );
}

export default LoadingData;