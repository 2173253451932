import Provider from './Provider';
import Language from './Language';
import Country from './Country';

class ItemMetadata {
    constructor(itemMetadata) {
        this.id = itemMetadata.id;
        this.originalId = itemMetadata.originalId;
        this.budget = itemMetadata.budget;
        this.revenue = itemMetadata.revenue;
        this.lastAirDate = itemMetadata.lastAirDate;
        this.lastUpdateDate = itemMetadata.lastUpdateDate;
        this.provider = new Provider(itemMetadata.provider);
        this.originalLanguage = new Language(itemMetadata.originalLanguage);

        this.country = [];
        itemMetadata.country.map(c => {
            this.country.push(new Country(c));
        })
    }

    generateGoUrl(type, additionnalFolder = null) {
        if (!this.provider || !type) {
            return '';
        }

        const typeKey = this.provider.goUrlNeedsSimpleKey ? type.simpleKey : (this.provider.goUrlNeedsKeyFr ? type.keyFr : type.key);

        return this.provider.generateGoUrl(typeKey, this.originalId) + (additionnalFolder ? additionnalFolder : '');
    }
}

export default ItemMetadata;