class Provider {
    constructor(provider) {
        this.id = provider.id;
        this.name = provider.name;
        this.goUrl = provider.goUrl;
        this.goUrlNeedsSimpleKey = provider.goUrlNeedsSimpleKey;
        this.goUrlNeedsKeyFr = provider.goUrlNeedsKeyFr;
        this.creditsFolder = provider.creditsFolder;
        this.triviaFolder = provider.triviaFolder;
        this.pictureBaseUrl = provider.pictureBaseUrl;
        this.order = provider.order;
    }

    generateGoUrl(typeKey, originalId) {
        return this.goUrl.replace('{typeKey}', typeKey).replace('{originalId}', originalId);
    }
}

export default Provider;