import { format } from 'date-fns'

import { DEFAULT_VALUE } from '../config/appConfig'

export function toDisplayDate(date, defaultValue = DEFAULT_VALUE) {
    return date ? format(new Date(date), 'dd/MM/yyyy') : defaultValue;
}

export function toDisplayDateWithShortTime(date, defaultValue = DEFAULT_VALUE) {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : defaultValue;
}

export function toDisplayDateWithTime(date, defaultValue = DEFAULT_VALUE) {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm:ss') : defaultValue;
}