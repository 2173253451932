import Type from './Type';
import UserItem from './UserItem.js';

import { DEFAULT_VALUE } from '../config/appConfig'

class Item {
    constructor(item) {
        this.id = item.id;
        this.originalTitle = item.originalTitle;
        this.localizedTitle = item.localizedTitle;
        this.releaseDate = Date.parse(item.releaseDate);
        this.rating = item.rating;
        this.type = new Type(item.type);
        this.userItem = new UserItem(item.userItem);

        this.fullTitle = this.originalTitle + (this.localizedTitle ? ' (' + this.localizedTitle + ')' : '');
        this.bestTitle = this.localizedTitle ?? this.originalTitle;
    }

    getYear(defaultValue = DEFAULT_VALUE) {
        return this.releaseDate ? new Date(this.releaseDate).getFullYear() : DEFAULT_VALUE;
    }
}

export default Item;