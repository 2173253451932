import { useTranslate } from 'react-polyglot';
import { Card } from 'react-bootstrap';

import { SetTitle } from '../helpers/titleHelper'

const Error = () => {
    const t = useTranslate();
    SetTitle('common_page_not_found');

    return (
        <Card border="danger" className="mb-3">
            <Card.Header>{t('common_error')}</Card.Header>
            <Card.Body className="text-danger">
                <Card.Title>{t('common_page_not_found')}</Card.Title>
                <Card.Text>{t('common_page_not_found_text')}</Card.Text>
            </Card.Body>
        </Card>
    );
}

export default Error;