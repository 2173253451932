import { Col, InputGroup, Form } from 'react-bootstrap'
import { useTranslate } from 'react-polyglot';

import Type from '../models/Type'

const TypesDropdown = ({ types, selectedKey, handleTypeChange }) => {
    const t = useTranslate();

    var list = [];

    types.results.map(type => {
        var t = new Type(type);
        list.push(t);
    });

    const handleValueChange = e => {
        handleTypeChange(e.target.value);
    }

    return (
        <Col xxl={3} className="mb-3">
            <InputGroup>
                <label className="input-group-text" htmlFor="types">{t('common_type')}</label>
                <Form.Select id="types" value={selectedKey} onChange={handleValueChange}>
                    {
                        list.map(type => (
                            <option value={type.simpleKey} key={type.simpleKey}>{type.label} ({type.count})</option>
                        ))
                    }
                </Form.Select>
            </InputGroup>
        </Col>
    );
}

export default TypesDropdown;