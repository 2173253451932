class Type {
    constructor(type) {
        this.id = type.id;
        this.label = type.label;
        this.simpleKey = type.simpleKey;
        this.key = type.key;
        this.keyFr = type.keyFr;
        this.order = type.order;
        this.count = type.count;
    }
}

export default Type;