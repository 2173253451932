class Paging {
    constructor(paging) {
        this.currentPage = paging.currentPage;
        this.pageCount = paging.pageCount;
        this.pageSize = paging.pageSize;
        this.rowCount = paging.rowCount;
        this.isFirstPage = paging.isFirstPage;
        this.isLastPage = paging.isLastPage;
        this.firstRowOnPage = paging.firstRowOnPage;
        this.lastRowOnPage = paging.lastRowOnPage;
    }
}

export default Paging;