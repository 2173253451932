import * as cfg from '../config/appConfig'

export function getLastSyncDate() {
    var url =
        [`${process.env.REACT_APP_API_BASE_URL}/`,
        `${cfg.ENDPOINT_LAST_SYNC}`].join('');

    return url;
}

export function getTypes(userId, itemType, page = 1, pageSize = cfg.PAGING_PAGE_SIZE_DEFAULT) {
    var url =
        [`${process.env.REACT_APP_API_BASE_URL}/`,
        `${cfg.ENDPOINT_TYPES}?`,
        `${cfg.ENDPOINT_PARAM_USER_ID}=${userId}&`,
        `${cfg.ENDPOINT_PARAM_ITEM_TYPE}=${itemType}&`,
        `${cfg.ENDPOINT_PARAM_PAGE}=${page}&`,
        `${cfg.ENDPOINT_PARAM_PAGE_SIZE}=${pageSize}`].join('');

    return url;
}

export function getItems(userId, typeSimpleKey, itemType, sortKeys, searchText, page = 1, pageSize = cfg.PAGING_PAGE_SIZE_DEFAULT) {
    var url =
        [`${process.env.REACT_APP_API_BASE_URL}/`,
        `${cfg.ENDPOINT_ITEMS}?`,
        `${cfg.ENDPOINT_PARAM_USER_ID}=${userId}&`,
        `${cfg.ENDPOINT_PARAM_TYPE_SIMPLE_KEY}=${typeSimpleKey}&`,
        `${cfg.ENDPOINT_PARAM_ITEM_TYPE}=${itemType}&`,
        `${cfg.ENDPOINT_PARAM_SORT_KEY}=${sortKeys}&`,
        `${cfg.ENDPOINT_PARAM_SEARCH_TEXT}=${searchText}&`,
        `${cfg.ENDPOINT_PARAM_PAGE}=${page}&`,
        `${cfg.ENDPOINT_PARAM_PAGE_SIZE}=${pageSize}`].join('');

    return url;
}

export function getItem(itemId, userId) {
    var url =
        [`${process.env.REACT_APP_API_BASE_URL}/`,
        `${cfg.ENDPOINT_ITEMS}/`,
        `${itemId}?`,
        `${cfg.ENDPOINT_PARAM_USER_ID}=${userId}`].join('');

    return url;
}

export function getSyncAll(userId, sensCritiqueUserId, syncToken) {
    var url =
        [`${process.env.REACT_APP_SYNC_API_BASE_URL}/`,
        `${cfg.ENDPOINT_SYNC_ALL}?`,
        `${cfg.ENDPOINT_PARAM_USER_ID}=${userId}&`,
        `${cfg.ENDPOINT_PARAM_SENS_CRITIQUE_USER_ID}=${sensCritiqueUserId}&`,
        `${cfg.ENDPOINT_PARAM_TOKEN}=${syncToken}`].join('');

    return url;
}