import { useState, useEffect } from 'react';

import { HEADER_TOKEN_KEY } from '../config/appConfig'

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        const abortCont = new AbortController();

        var myHeaders = new Headers({ [HEADER_TOKEN_KEY]: process.env.REACT_APP_HEADER_TOKEN_VALUE });

        setTimeout(() => {
            fetch(url, { headers: myHeaders, signal: abortCont.signal })
                .then(res => {
                    if (!res.ok) {
                        throw Error('Could not fetch the data for that resource.');
                    }

                    return res.json();
                })
                .then((items) => {
                    setData(items);
                    setError(null);
                    setIsLoading(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError' && process.env.NODE_ENV === 'development') {
                        console.log(`Fetch of url ${url} aborted - ${err}`);
                    }
                    else {
                        setError(err.message);
                        setIsLoading(false);
                    }
                })
        }, 0)

        return () => abortCont.abort();
    }, [url]);

    return { data, isLoading, error }
}

export default useFetch