export const ENDPOINT_LAST_SYNC = 'lastSync';
export const ENDPOINT_COUNTRIES = 'countries';
export const ENDPOINT_TYPES = 'types';
export const ENDPOINT_ITEMS = 'items';
export const ENDPOINT_SYNC_ALL = 'syncAll';

export const ENDPOINT_PARAM_USER_ID = 'userId';
export const ENDPOINT_PARAM_SENS_CRITIQUE_USER_ID = 'sensCritiqueUserId';
export const ENDPOINT_PARAM_TOKEN = 'token';
export const ENDPOINT_PARAM_TYPE_SIMPLE_KEY = 'typeSimpleKey';
export const ENDPOINT_PARAM_ITEM_TYPE = 'itemType';
export const ENDPOINT_PARAM_SORT_KEY = 'sortKey';
export const ENDPOINT_PARAM_SEARCH_TEXT = 'searchText';
export const ENDPOINT_PARAM_PAGE = 'page';
export const ENDPOINT_PARAM_PAGE_SIZE = 'pageSize';

export const ENDPOINT_PARAM_TYPE_SIMPLE_KEY_DEFAULT_VALUE = 'all';

export const ENDPOINT_PARAM_ITEM_TYPE_ALL = 'all';
export const ENDPOINT_PARAM_ITEM_TYPE_RATED = 'rated';
export const ENDPOINT_PARAM_ITEM_TYPE_WISHLISTED = 'wishlisted';

export const PAGING_PAGE_SIZE_DEFAULT = 30;

export const COOKIE_TYPE = 'type';
export const COOKIE_SORT = 'sort';
export const COOKIE_SEARCH_TEXT = 'search_text';
export const COOKIE_PAGE = 'page';

export const HEADER_TOKEN_KEY = 'token';

export const DEFAULT_VALUE = '-';