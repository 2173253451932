import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

import { useTranslate } from 'react-polyglot';

import { SetTitle } from '../helpers/titleHelper'

import FullItem from '../models/FullItem'
import ItemDetailsContentLeft from '../components/ItemDetailsContentLeft'
import ItemDetailsContentRight from '../components/ItemDetailsContentRight'

const ItemDetailsContent = ({ item }) => {
    const t = useTranslate();
    const i = new FullItem(item);
    SetTitle('page_items_details', { type: i.type.label, name: i.fullTitle });

    const isFromWishList = window.location.href.indexOf('wishlist/items') > -1;

    return (
        <div>
            <Row>
                <Col xxl={12}>
                    <Link className="mt-2 btn btn-outline-dark" to={`/${isFromWishList ? 'wishlist/' : ''}`}>← {t('common_back')}</Link>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <h1>{i.originalTitle + (i.releaseDate ? ' (' + i.getYear() + ')' : '')}</h1>
                {i.localizedTitle && <h3>{i.localizedTitle}</h3>}
            </Row>
            <Row className="margin-bottom">
                <Col>
                    <a href={i.generateGoToRedditUrl()} target="_blank" className="margin-right">
                        <img src={"./icons/ic-reddit.svg"} className="see-on-img" title={t('common_see_on', { site_name: 'Reddit' })} alt={t('common_see_on', { site_name: 'Reddit' })} />
                    </a>
                    {i.hasImdbMetadata() &&
                        (
                            <a href={i.triviaOnImdb()} target="_blank">
                                <img src={"./icons/ic-imdb.svg"} className="see-on-img" title={t('common_trivias_on', { site_name: 'IMDb' })} alt={t('common_see_on', { site_name: 'Reddit' })} />
                            </a>
                        )}
                </Col>
            </Row>
            <Row>
                <Col xxl={5}>
                    <ItemDetailsContentLeft item={i} />
                </Col>
                <Col xxl={5}>
                    <ItemDetailsContentRight item={i} />
                </Col>
            </Row>
        </div >
    );
}

export default ItemDetailsContent;