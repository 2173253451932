import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { useTranslate } from 'react-polyglot';

import { getLastSyncDate, getSyncAll } from '../helpers/apiHelper'
import { toDisplayDateWithShortTime } from '../helpers/dateHelper'

import { HEADER_TOKEN_KEY } from '../config/appConfig'

const MyNavbar = () => {
    const t = useTranslate();

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isSyncAllLoading, setIsSyncAllLoading] = useState(false);

    useEffect(() => {
        performLastSyncDate();
    }, []);

    function performLastSyncDate() {
        fetch(getLastSyncDate(), { headers: new Headers({ [HEADER_TOKEN_KEY]: process.env.REACT_APP_HEADER_TOKEN_VALUE }) })
            .then(res => {
                return res.json();
            })
            .then((items) => {
                setData(items);
                setError(null);
                setIsLoading(false);
                setIsSyncAllLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setIsLoading(false);
                setIsSyncAllLoading(false);
            });
    }

    const syncAll = () => {
        setIsSyncAllLoading(true);

        fetch(getSyncAll(process.env.REACT_APP_USER_ID, process.env.REACT_APP_SENS_CRITIQUE_USER_ID, process.env.REACT_APP_SYNC_API_TOKEN))
            .then(res => {
                return res.json();
            })
            .then(() => {
                performLastSyncDate();
            })
            .catch(err => {
                console.log(err);
                setIsSyncAllLoading(false);
            });
    }

    return (
        <header>
            <Navbar bg="white" expand="sm" className="border-bottom box-shadow mb-3">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={"./logo.png"} className="logo" alt={process.env.REACT_APP_NAME} title={process.env.REACT_APP_NAME} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Link to="/" className="nav-link">{t('page_ratings_list')}</Link>
                        <Link to="/wishlist" className="nav-link">{t('page_wish_list')}</Link>
                        <NavDropdown title={t('page_tmdb')} >
                            <NavDropdown.Item href="https://www.themoviedb.org/list/8243430" target="_blank">{t('page_tmdb_movies_list')}</NavDropdown.Item>
                            <NavDropdown.Item href="https://www.themoviedb.org/list/8243431" target="_blank">{t('page_tmdb_tv_series_list')}</NavDropdown.Item>
                        </NavDropdown>
                        <div className="d-flex ms-auto">
                            {isSyncAllLoading && <img className="loading-sync-all" src={"./btn_loading.gif"} alt={`${t('common_loading')}...`} />}
                            <div className="me-2" style={{ "textAlign": "center" }}>
                                {t('common_last_update')}
                                <br />
                                <div className="home">
                                    {error && <div>{error}</div>}
                                    {isLoading && <div>{`${t('common_loading')}...`}</div>}
                                    {data && toDisplayDateWithShortTime(data.Date)}
                                </div>
                            </div>
                            <Button variant="outline-success" onClick={syncAll} disabled={isSyncAllLoading}>{t('common_sync_all')}</Button>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default MyNavbar;