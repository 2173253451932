import { Col, InputGroup, Form } from 'react-bootstrap'
import { useTranslate } from 'react-polyglot';

const SortDropdown = ({ selectedSort, handleSortChange }) => {
    const t = useTranslate();

    const sort = {
        CONSUMED_DATE_ASC: `${t('sort_consumed_date')} ↑`,
        CONSUMED_DATE_DESC: `${t('sort_consumed_date')} ↓`,
        GENERAL_RATING_ASC: `${t('sort_general_rating')} ↑`,
        GENERAL_RATING_DESC: `${t('sort_general_rating')} ↓`,
        POPULARITY_ASC: `${t('sort_popularity')} ↑`,
        POPULARITY_DESC: `${t('sort_popularity')} ↓`,
        RELEASE_DATE_ASC: `${t('sort_release_date')} ↑`,
        RELEASE_DATE_DESC: `${t('sort_release_date')} ↓`,
        TITLE_ASC: `${t('sort_title')} ↑`,
        TITLE_DESC: `${t('sort_title')} ↓`,
        USER_RATING_ASC: `${t('sort_user_rating')} ↑`,
        USER_RATING_DESC: `${t('sort_user_rating')} ↓`
    }

    const handleValueChange = e => {
        handleSortChange(e.target.value);
    }

    return (
        <Col xxl={3} className="mb-3">
            <InputGroup>
                <label className="input-group-text" htmlFor="sorts">{t('common_sort')}</label>
                <Form.Select id="sorts" value={selectedSort ?? Object.keys(sort)[1]} onChange={handleValueChange}>
                    {
                        Object.keys(sort).map(key => (
                            <option title={sort[key]} value={key} key={key}>{sort[key]}</option>
                        ))
                    }
                </Form.Select>
            </InputGroup>
        </Col >
    );
}

export default SortDropdown;