import { Link } from "react-router-dom";
import { Row, Col, Card, Table } from 'react-bootstrap';

import { useTranslate } from 'react-polyglot';

import { SetTitle } from '../helpers/titleHelper'
import { toDisplayDate } from '../helpers/dateHelper'
import { toRating } from '../helpers/ratingHelper'

import FullItem from '../models/FullItem'

const ItemDetailsContentLeft = ({ item }) => {
    const t = useTranslate();
    const i = item;

    return (
        <Card className="mb-3">
            <Row className="g-0">
                <Col md={5} className="fill">
                    <img src={i.fullPictureUrl} className="card-img-top" alt={i.bestTitle} />
                </Col>
                <Col md={7}>
                    <Card.Body>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>{t('common_id')}</th>
                                    <td className="text-end">{i.originalId}</td>
                                </tr>
                                <tr>
                                    <th>{t('common_date_seen')}</th>
                                    <td className="text-end">{toDisplayDate(i.userItem.actionOnItemDate)}</td>
                                </tr>
                                <tr>
                                    <th>{t('common_personal_rating')}</th>
                                    <td className="text-end">
                                        {i.userItem.userRating && i.rating &&
                                            (i.userItem.userRating >= i.rating ?
                                                <span className="margin-right badge bg-success">↑</span> : <span className="margin-right badge bg-danger">↓</span>
                                            )}
                                        {toRating(i.userItem.userRating, true)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('common_general_rating')}</th>
                                    <td className="text-end">
                                        {toRating(i.rating)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <a className="w-100 btn btn-primary" href={i.generateGoUrl()} target="_blank">{t('common_see_on', { site_name: i.provider.name })}</a>
                        <a className="w-100 mt-1 btn btn-warning" href={i.generateGoUrl(i.provider.creditsFolder)} target="_blank">{t('common_credits_on', { site_name: i.provider.name })}</a>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
}

export default ItemDetailsContentLeft;