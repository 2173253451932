import { Link } from "react-router-dom";
import { Row, Col, Card, Table } from 'react-bootstrap';

import { useTranslate } from 'react-polyglot';

import { SetTitle } from '../helpers/titleHelper'
import { toDisplayDate, toDisplayDateWithTime } from '../helpers/dateHelper'
import { toDotsSeparatedNumber } from '../helpers/numberHelper'
import { toRating } from '../helpers/ratingHelper'

import FullItem from '../models/FullItem'

const ItemDetailsContentRight = ({ item }) => {
    const t = useTranslate();

    return (
        <div>
            {item.itemMetadata && (
                item.itemMetadata.sort((a, b) => a.provider.order - b.provider.order).map(im => {
                    return (
                        <Card className="mb-3" key={im.id}>
                            <Card.Header>{im.provider.name}</Card.Header>
                            <Card.Body>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>{t('common_id')}</th>
                                            <td className="text-end">{im.originalId}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('common_last_update_date')}</th>
                                            <td className="text-end">{toDisplayDateWithTime(im.lastUpdateDate)}</td>
                                        </tr>
                                        {im.originalLanguage.id &&
                                            (
                                                <tr>
                                                    <th>{t('common_original_language')}</th>
                                                    <td className="text-end">{im.originalLanguage.name}</td>
                                                </tr>
                                            )
                                        }
                                        {im.country.length > 0 &&
                                            (
                                                <tr>
                                                    <th>{t('common_countries')}</th>
                                                    <td className="text-end">{im.country.map(c => (c.name)).join(', ')}</td>
                                                </tr>
                                            )
                                        }
                                        {im.lastAirDate &&
                                            (
                                                <tr>
                                                    <th>{t('common_last_air_date')}</th>
                                                    <td className="text-end">{toDisplayDate(im.lastAirDate)}</td>
                                                </tr>
                                            )
                                        }
                                        {im.budget > 0 &&
                                            (
                                                <tr>
                                                    <th>{t('common_budget')}</th>
                                                    <td className="text-end">
                                                        {toDotsSeparatedNumber(im.budget)} $
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {im.revenue > 0 &&
                                            (
                                                <tr>
                                                    <th>{t('common_revenue')}</th>
                                                    <td className="text-end">
                                                        {im.revenue && im.budget && (
                                                            im.revenue >= im.budget ?
                                                                <span className="margin-right badge bg-success">↑</span> : <span className="margin-right badge bg-danger">↓</span>
                                                        )}
                                                        {toDotsSeparatedNumber(im.revenue)} $
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                <a className="w-100 btn btn-primary" href={im.generateGoUrl(item.type)} target="_blank">
                                    {t('common_see_on', { site_name: im.provider.name })}
                                </a>
                                <br />
                                <a className="mt-1 w-100 btn btn-warning" href={im.generateGoUrl(item.type, im.provider.creditsFolder)} target="_blank">
                                    {t('common_credits_on', { site_name: im.provider.name })}
                                </a>
                                {im.provider.triviaFolder &&
                                    (
                                        <a className="mt-1 w-100 btn btn-info" href={im.generateGoUrl(item.type, im.provider.triviaFolder)} target="_blank">
                                            {t('common_trivias_on', { site_name: im.provider.name })}
                                        </a>
                                    )}
                                <br />
                            </Card.Body>
                        </Card>
                    )
                })
            )}
        </div>
    );
}

export default ItemDetailsContentRight;