import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap'
import { useTranslate } from 'react-polyglot';

import Item from '../models/Item';

import { getPagedIndex } from '../helpers/pagingHelper';
import { toDisplayDate } from '../helpers/dateHelper'
import { toRating } from '../helpers/ratingHelper'
import { ellipsize } from '../helpers/stringHelper'

const ItemList = ({ items, isWishList }) => {
    const t = useTranslate();

    const paging = items.paging;

    return (
        <div>
            {items.results.length == 0 && <td className="col-6">{t('common_no_items')}</td>}
            {items.results.length > 0 && (
                <Table>
                    <thead>
                        <tr>
                            <th className="d-none d-lg-table-cell">#</th>
                            <th className="d-none d-lg-table-cell col-5">{t('common_title')}</th>
                            <th className="d-lg-none">{t('common_title')}</th>
                            <th className="d-none d-lg-table-cell col-1">{t('common_type')}</th>
                            <th className="d-none d-lg-table-cell">{t('common_release_year')}</th>
                            <th className="d-none d-lg-table-cell">{t('common_consumption_date')}</th>
                            <th className="d-none d-lg-table-cell">{t('common_personal_rating')}</th>
                            <th className="d-lg-none text-end">{t('common_rating')}</th>
                            <th className="d-none d-lg-table-cell">{t('common_general_rating')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.results.map((item, index) => {
                            const i = new Item(item);
                            return (
                                <tr key={item.id}>
                                    <th className="d-none d-lg-table-cell" scope="row">{getPagedIndex(paging.currentPage, paging.pageSize, paging.rowCount, index, false)}</th>
                                    <td className="d-none d-lg-table-cell"><Link to={`/${isWishList ? 'wishlist/' : ''}items/details/${i.id}`}>{i.fullTitle}</Link></td>
                                    <td className="d-lg-none"><Link to={`/${isWishList ? 'wishlist/' : ''}items/details/${i.id}`}>{ellipsize(i.fullTitle, 30)}</Link></td>
                                    <td className="d-none d-lg-table-cell">{i.type.label}</td>
                                    <td className="d-none d-lg-table-cell">{i.getYear()}</td>
                                    <td className="d-none d-lg-table-cell">{toDisplayDate(i.userItem.actionOnItemDate)}</td>
                                    <td className="d-none d-lg-table-cell text-end">{toRating(i.userItem.userRating, true)}</td>
                                    <td className="d-lg-none text-end">{toRating(i.userItem.userRating, true)}</td>
                                    <td className="d-none d-lg-table-cell text-end">{toRating(i.rating)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table >
            )}
        </div>
    );
}

export default ItemList;