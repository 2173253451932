import { useState, useEffect, useRef } from 'react'
import { Col, InputGroup, Form, Button } from 'react-bootstrap'
import { useTranslate } from 'react-polyglot';

const SearchField = ({ initialValue, handleSearchChange }) => {
    const t = useTranslate();
    const [searchText, setSearchText] = useState(initialValue);
    const searchRef = useRef(null);

    const handleValueChange = e => {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        setSearchText(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearchChange(searchText);
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchText])

    function clearSearchText() {
        setSearchText('');
        searchRef.current.focus();
    }

    return (
        <Col xxl={6} className="mb-3">
            <InputGroup>
                <label className="input-group-text" htmlFor="search">{t('common_search')}</label>
                <Form.Control id="search" type="text" value={searchText} ref={searchRef} onChange={handleValueChange} />
                <Button variant="outline-secondary" onClick={clearSearchText}>X</Button>
            </InputGroup>
        </Col>
    );
}

export default SearchField;