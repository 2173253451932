import { Row, InputGroup, Button, Form, Col } from 'react-bootstrap'
import { useTranslate } from 'react-polyglot';

import P from '../models/Paging'

const Paging = ({ paging, currentPage, isLoading, handlePageChange }) => {
    const t = useTranslate();
    const p = new P(paging);

    const handleValueChange = e => {
        handlePageChange(e.target.value);
    }

    return (
        p.pageCount > 0 && (!p.isFirstPage || !p.isLastPage) &&
        (<Row>
            <Col>
                <div style={{ 'width': '230px' }}>
                    <InputGroup>
                        {!p.isFirstPage ? <Button variant="outline-dark" onClick={() => handlePageChange(1)}>&laquo;</Button> : <Button variant="outline-dark" disabled>&laquo;</Button>}
                        {!p.isFirstPage ? <Button variant="outline-dark" onClick={() => handlePageChange(p.currentPage - 1)}>&lsaquo;</Button> : <Button variant="outline-dark" disabled>&lsaquo;</Button>}

                        <Form.Select value={currentPage} onChange={handleValueChange} style={{ 'border': '1px solid #212529' }}>
                            {
                                [...Array(p.pageCount).keys()].map(index => (
                                    <option title={index + 1} value={index + 1} key={index}>{index + 1}</option>
                                ))
                            }
                        </Form.Select>

                        {!p.isLastPage ? <Button variant="outline-dark" onClick={() => handlePageChange(p.currentPage + 1)}>&rsaquo;</Button> : <Button variant="outline-dark" disabled>&rsaquo;</Button>}
                        {!p.isLastPage ? <Button variant="outline-dark" onClick={() => handlePageChange(p.pageCount)}>&raquo;</Button> : <Button variant="outline-dark" disabled>&raquo;</Button>}
                    </InputGroup>
                </div>
            </Col>
            <Col>
                {isLoading && <img className="loading-paging" src={"./btn_loading.gif"} alt={`${t('common_loading')}...`} />}
            </Col>
        </Row >)
    );
}

export default Paging;