class UserItem {
    constructor(userItem) {
        this.actionOnItemDate = userItem.actionOnItemDate;
        this.userRating = userItem.userRating;

        if (userItem.isInUserWishList !== undefined) {
            this.isInUserWishList = userItem.isInUserWishList ? true : false;
        }
    }
}

export default UserItem;