import { createContext, useState, useEffect } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import { I18n } from 'react-polyglot'

import Home from "./Home";
import ItemDetails from "./ItemDetails";
import Error from "./Error";
import MyNavbar from "./MyNavbar";
import Footer from "./Footer";

import { ENDPOINT_PARAM_ITEM_TYPE_RATED, ENDPOINT_PARAM_ITEM_TYPE_WISHLISTED } from '../config/appConfig'

function App() {
  const LocaleContext = createContext();

  const [locale, setLocale] = useState('fr');
  const [messages, setMessages] = useState({});

  useEffect(() => {
    fetch(`./translations/${locale}.json`)
      .then(res => {
        return res.json();
      })
      .then(data => {
        setMessages(data);
      });
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <I18n locale={locale} messages={messages} allowMissing={true}>
        <HashRouter>
          <MyNavbar />
          <Container>
            <main role="main" className="pb-3">
              <Routes>
                <Route path="/wishlist" element={<Home itemType={ENDPOINT_PARAM_ITEM_TYPE_WISHLISTED} />} />
                <Route path="/" element={<Home itemType={ENDPOINT_PARAM_ITEM_TYPE_RATED} />} />
                <Route path="/items/details/:id" element={<ItemDetails />} />
                <Route path="/wishlist/items/details/:id" element={<ItemDetails />} />
                <Route path="/404" element={<Error />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Routes>
            </main>
          </Container>
          <Footer />
        </HashRouter>
      </I18n>
    </LocaleContext.Provider>
  );
}

export default App;