import { useState, useEffect } from 'react';
import { useTranslate } from 'react-polyglot';
import { useCookies } from 'react-cookie';
import { Row, Col, Button } from 'react-bootstrap';

import LoadingData from './LoadingData';
import TypesDropdown from './TypesDropdown';
import SortDropdown from './SortDropdown';
import SearchField from './SearchField';
import Paging from './Paging';
import ItemList from './ItemList';

import useFetch from '../hooks/useFetch';

import { getTypes, getItems } from '../helpers/apiHelper'
import { SetTitle } from '../helpers/titleHelper'

import {
    ENDPOINT_PARAM_ITEM_TYPE_WISHLISTED, ENDPOINT_PARAM_TYPE_SIMPLE_KEY_DEFAULT_VALUE,
    COOKIE_TYPE, COOKIE_SORT, COOKIE_PAGE, COOKIE_SEARCH_TEXT
} from '../config/appConfig'

const Home = ({ itemType }) => {
    const t = useTranslate();
    const isWishList = itemType === ENDPOINT_PARAM_ITEM_TYPE_WISHLISTED;
    const pageTitleRes = isWishList ? 'page_wish_list' : 'page_ratings_list';
    SetTitle(pageTitleRes);

    const [cookies, setCookie] = useCookies();

    const [typeSimpleKey, setTypeSimpleKey] = useState(cookies[COOKIE_TYPE] ?? ENDPOINT_PARAM_TYPE_SIMPLE_KEY_DEFAULT_VALUE);
    const [sortKey, setSortKey] = useState(cookies[COOKIE_SORT]);
    const [searchText, setSearchText] = useState(cookies[COOKIE_SEARCH_TEXT] ?? '');
    const [page, setPage] = useState(cookies[COOKIE_PAGE] ?? 1);
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    const { data: types } = useFetch(getTypes(process.env.REACT_APP_USER_ID, itemType));
    const { data: items, isLoading, error } = useFetch(getItems(process.env.REACT_APP_USER_ID, typeSimpleKey, itemType, sortKey, searchText, page));

    useEffect(() => {
        if (isFirstLoading && !isLoading) {
            setIsFirstLoading(false);
        }
    }, [isLoading]);

    const resetFilters = () => {
        changeTypeAndSave(ENDPOINT_PARAM_TYPE_SIMPLE_KEY_DEFAULT_VALUE);
        changeSortAndSave(null);
        changeSearchTextAndSave('');
        changePageAndSave(1);
    }

    const handleTypeChange = typeSimpleKey => {
        changeTypeAndSave(typeSimpleKey);
        changePageAndSave(1);
    }

    const handleSortChange = sortKey => {
        changeSortAndSave(sortKey);
        changePageAndSave(1);
    }

    const handlePageChange = num => {
        changePageAndSave(num);
    }

    const handleSearchChange = searchText => {
        changeSearchTextAndSave(searchText)
    }

    function changeTypeAndSave(value) {
        setTypeSimpleKey(value);
        setCookie(COOKIE_TYPE, value);
    }

    function changeSortAndSave(value) {
        setSortKey(value);
        setCookie(COOKIE_SORT, value);
    }

    function changeSearchTextAndSave(value) {
        setSearchText(value);
        setCookie(COOKIE_SEARCH_TEXT, value);
    }

    function changePageAndSave(value) {
        setPage(value);
        setCookie(COOKIE_PAGE, value);
    }

    return (
        <div className="home">
            <h2>{t(pageTitleRes)}</h2>
            {error && <div>{error}</div>}
            {isFirstLoading && <LoadingData />}
            {types && items && (
                <div>
                    <Row>
                        <TypesDropdown types={types} selectedKey={typeSimpleKey} handleTypeChange={handleTypeChange} />
                        <SortDropdown selectedSort={sortKey} handleSortChange={handleSortChange} />
                    </Row>
                    <Row>
                        <SearchField initialValue={searchText} handleSearchChange={handleSearchChange} />
                        <Col xxl={1} className="mb-3">
                            <Button variant="success" onClick={resetFilters}>{t('common_reset')}</Button>
                        </Col>
                    </Row>
                    <Paging paging={items.paging} currentPage={page} isLoading={isLoading} handlePageChange={handlePageChange} />
                    <ItemList items={items} isWishList={isWishList} />
                    <Paging paging={items.paging} currentPage={page} isLoading={isLoading} handlePageChange={handlePageChange} />
                </div>
            )}
        </div>
    );
}

export default Home;