import Item from './Item';
import Provider from './Provider';
import Genre from './Genre.js';
import ItemMetadata from './ItemMetadata.js';

class FullItem extends Item {
    constructor(fullItem) {
        super(fullItem);

        this.originalId = fullItem.originalId;
        this.pictureUrl = fullItem.pictureUrl;
        this.productionYear = fullItem.productionYear;
        this.durationInSeconds = fullItem.durationInSeconds;
        this.ratingsCount = fullItem.ratingsCount;
        this.reviewsCount = fullItem.reviewsCount;
        this.wishListsCount = fullItem.wishListsCount;
        this.provider = new Provider(fullItem.provider);

        this.genre = [];
        fullItem.genre.map(g => {
            this.genre.push(new Genre(g));
        })

        this.itemMetadata = [];
        fullItem.itemMetadata.map(im => {
            this.itemMetadata.push(new ItemMetadata(im));
        })

        this.fullPictureUrl = this.provider.pictureBaseUrl + this.pictureUrl.replace("{size}", "500");
    }

    generateGoUrl(additionnalFolder = null) {
        if (!this.provider || !this.type) {
            return '';
        }

        const typeKey = this.provider.goUrlNeedsKeyFr ? this.type.keyFr : this.type.key;

        return this.provider.generateGoUrl(typeKey, this.originalId) + (additionnalFolder ? additionnalFolder : '');
    }

    generateGoToRedditUrl() {
        var titleToUse = this.originalTitle;

        if (['movie', 'tv', 'game'].includes(this.type.simpleKey)) {
            var isOriginalLanguageEn = false;

            if (this.itemMetadata.some(md => md.provider.name == 'TMDb')) {
                isOriginalLanguageEn = this.itemMetadata.filter(md => md.provider.name == 'TMDb')[0].originalLanguage.code == 'en';
            }

            titleToUse = isOriginalLanguageEn ? this.originalTitle : this.bestTitle;

            if (this.type.simpleKey == 'movie' && this.releaseDate != null) {
                titleToUse += ` ${this.getYear()}`;
            }
        }

        var rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
        return `https://www.google.com/search?q=${titleToUse} ${this.type.key.replace(rex, '$1$4 $2$3$5')} reddit`; // camelCasedString => camel Cased String

    }

    hasImdbMetadata() {
        return this.itemMetadata.some(md => md.provider.name == 'IMDb');
    }

    triviaOnImdb() {
        if (this.hasImdbMetadata()) {
            var imdb = this.itemMetadata.filter(md => md.provider.name == 'IMDb')[0];
            return imdb.generateGoUrl(this.type, imdb.provider.triviaFolder);
        }

        return '';
    }
}

export default FullItem;