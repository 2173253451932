import { useParams } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import useFetch from '../hooks/useFetch';

import { getItem } from '../helpers/apiHelper'

import ItemDetailsContent from "./ItemDetailsContent";

const ItemDetails = () => {
    const { id } = useParams()

    const { data: item, isLoading, error } = useFetch(getItem(id, process.env.REACT_APP_USER_ID));

    return (
        <div className="item">
            {error && <div>{error}</div>}
            {item && <ItemDetailsContent item={item.results[0]} />}
        </div>
    );
}

export default ItemDetails;